// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'Helvetica', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

$color-links: #3097D1;

.btn-clear {
  padding: 0;
  background: none;
  color: $color-links;
}

.full-height {
  height: 80vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 30px;
}

.with-button {
  padding: 25px 15px;
  .btn {
    margin-top: -8px;
  }
}

.alert {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

table {
  td {
    vertical-align: middle!important;
  }
  td.action {
    width: 52px;
  }
  td.time {
    width: 150px;
  }
  td.word-break{
    word-break: break-all;
  }
}

.pull-right {
  .pull-right-block {
    display: inline-block;
  }
}

.sidebar-nav {
  @media (min-width: 768px) {
    .navbar {
      .navbar-collapse {
        padding: 0;
        max-height: none;
      }
      ul {
        float: none;
        display: block;
        li {
          float: none;
          display: block;
          a {
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }
      }
    }
  }
}